import React from 'react';
import { graphql } from 'gatsby';
import { arrayOf, object, shape, string } from 'prop-types';

import { Footer, Hero, SEO } from '../components';
import { Link } from '../components/elements';
import fourOhFourContent from '../content/404';

const FourOhFourPage = ({ data, location }) => {
  const path = location.pathname.split('/')[3];
  const { seo, headerImage } = data.allGraphCmsPage.edges[0].node;
  const { content } = fourOhFourContent;

  return (
    <>
      <SEO seo={seo} path={path} />
      <Hero heading={content.heading} image={headerImage} />
      <div className="container bg-white py-4 md:py-8 md:mt-16">
        <p className="pt-4 md:pt-8 pb-16 px-2 md:px-36">
          Learn about <Link path="/">connecting a car</Link>, check to see{' '}
          <Link path="/compatibility/">if your vehicle is compatible</Link>, or read our guides to
          help you <Link path="/guides/">set up your connected services account</Link>.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default FourOhFourPage;

FourOhFourPage.propTypes = {
  data: shape({
    allGraphCmsPage: shape({
      edges: arrayOf(
        shape({
          node: shape({
            headerImage: shape({
              // eslint-disable-next-line react/forbid-prop-types
              gatsbyImageData: object.isRequired,
            }),
            seo: shape({
              title: string.isRequired,
              metaDescription: string.isRequired,
            }).isRequired,
            theme: string,
          }).isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  }).isRequired,
  location: shape({
    pathname: string.isRequired,
  }).isRequired,
};

export const FourOhFourPageQuery = graphql`
  query FourOhFourPage {
    allGraphCmsPage(filter: { path: { eq: "index" } }) {
      edges {
        node {
          headerImage {
            gatsbyImageData(placeholder: NONE)
          }
          seo {
            title
            metaDescription
          }
          theme
        }
      }
    }
  }
`;

const fourOhFourContent = {
  seo: {
    title: 'Connect your car',
    description:
      'Learn more about connecting your car to a mobility app and creating a connected services account.',
  },
  content: {
    heading: "Sorry, looks like that page doesn't exist",
  },
};

export default fourOhFourContent;
